import React from 'react';
import styles from './footer.module.scss';
import { Hpadding } from "@fidize/ui.hpadding"
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from '@fidize/ui.responsive';
import { Vspace } from '@fidize/ui.vspace';
import { Link } from 'gatsby'
import logo from './static/fidize.png';
import {SiInstagram, SiFacebook} from 'react-icons/si';

export class Footer extends React.Component {
  render(){
      var desk =  <div className={styles.footer}>
                    <Vspace desk="70px" mobile="40px" />
                    <Hpadding padding="780" style={{height: 'auto'}}>
                      <Flex type="justify_spacebetween" style={{height: "unset"}}>
                          <Flex type="column" style={{width: "15%"}}>
                            <img src={logo} className={styles.imgDesk} style={{margin: "unset", width: "unset"}} />
                          </Flex>

                          <Flex type="column justify_spacebetween">
                            <Text type="white xs">Copyright © 2023. All rights reserved.</Text>
                            <Text type="grey xs">
                              Moward Srl
                              <br></br>
                              Viale Giulio Cesare, 47
                              <br></br>
                              00192 Roma
                              <br></br>
                              P.IVA: 16326391006
                            </Text>

                            <Text type="grey xs">Customer Service: info@fidize.com</Text>
                          </Flex>

                          <Flex type="column">
                            <Link to="/faq"><Text type="white xs bold" style={{marginBottom: "10px"}}>FAQ</Text></Link>
                            <Link to="/privacy"><Text type="white xs bold" style={{marginBottom: "10px"}}>PRIVACY POLICY</Text></Link>
                            <Link to="/terms-conditions"><Text type="white xs bold" style={{marginBottom: "10px"}}>TERMINI E CONDIZIONI</Text></Link>
                            <Text><br></br></Text>
                            <Flex>
                              <Link to="https://www.facebook.com/fidize.card"><Text type="white h2 bold" style={{marginBottom: "10px"}}><SiFacebook /></Text></Link>
                              <span style={{width: "10px"}}></span>
                              <Link to="https://www.instagram.com/fidize.card/"><Text type="white h2 bold" style={{marginBottom: "10px"}}><SiInstagram /></Text></Link>
                            </Flex>
                          </Flex>
                      </Flex>
                    </Hpadding>
                  </div>

      var mobile =  <div className={styles.footer}>
                      <Hpadding padding="mobile0" style={{height: '100%'}}>
                        <Vspace desk="70px" mobile="40px" />
                        <Flex type="column" style={{height: "100%"}}>
                            <Flex type="justify_center">
                              <Flex style={{width: '50%'}}>
                                <img src={logo} className={styles.img} />
                              </Flex>
                            </Flex>
                            <Vspace desk="65px" mobile="50px" />

                            <Flex type="justify_center" style={{marginBottom: "10px"}}>
                              <Link to="/faq"><Text type="white normal bold">FAQ</Text></Link>
                            </Flex>
                            <Flex type="justify_center" style={{marginBottom: "10px"}}>
                              <Link to="/privacy"><Text type="white normal bold">PRIVACY POLICY</Text></Link>
                            </Flex>
                            <Flex type="justify_center" style={{marginBottom: "10px"}}>
                              <Link to="/terms-conditions"><Text type="white normal bold">TERMINI E CONDIZIONI</Text></Link>
                            </Flex>

                            <Vspace desk="65px" mobile="30px" />
                            <Flex type="column justify_spacebetween" style={{width: "100%"}}>
                              <Text type="white xs" style={{textAlign: 'center'}}>Copyright © 2023. All rights reserved.</Text>
                              <Vspace desk="65px" mobile="30px" />
                              <Text type="grey normal" style={{textAlign: 'center'}}>
                                Moward Srl
                                <br></br>
                                Viale Giulio Cesare, 47
                                <br></br>
                                00192 Roma
                                <br></br>
                                P.IVA: 16326391006
                              </Text>
                              <Vspace desk="65px" mobile="30px" />
                              <Text type="grey normal" style={{textAlign: 'center'}}>Customer Service: info@fidize.com</Text>
                              <Vspace desk="65px" mobile="35px" />
                              <Flex type="justify_center">
                                <Link to="https://www.facebook.com/fidize.card"><Text type="white h2 bold" style={{marginBottom: "10px"}}><SiFacebook /></Text></Link>
                                <span style={{width: "10px"}}></span>
                                <Link to="https://www.instagram.com/fidize.card/"><Text type="white h2 bold" style={{marginBottom: "10px"}}><SiInstagram /></Text></Link>
                              </Flex>
                              <Vspace desk="65px" mobile="35px" />
                            </Flex>
                        </Flex>
                      </Hpadding>
                  </div>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}

import React from 'react';
import styles from './navigation.module.scss';
import { Link } from 'gatsby'
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Responsive } from "@fidize/ui.responsive"
import { BsBag, BsHouseDoor } from 'react-icons/bs'
import logo from './static/fidize.png'

export class Navigation extends React.Component {
  render(){
      var mobile = <div className={styles.navigation}>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
                    <Flex style={{width: "100%", height: "100%"}}>
                      <Flex style={{width: "33.333%"}}>
                        <Flex type="column justify_center">
                          <Link to="/" >
                            <Text type="h3 white"><BsHouseDoor /></Text>
                          </Link>
                        </Flex>
                      </Flex>

                      <Flex style={{width: "33.333%"}} type="justify_center">
                        <Flex type="column justify_center">
                            <Link to="/">
                              <img src={logo} className={styles.logo} />
                            </Link>
                        </Flex>
                      </Flex>

                      <Flex style={{width: "33.333%"}} type="justify_end">
                        <Flex type="column justify_center">
                          <Link to="/cart" >
                            <Text type="h3 white"><BsBag /></Text>
                          </Link>
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
                  
      var desk = <div className={styles.navigationDesk}>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
                    <Flex style={{width: "100%", height: "100%"}}>
                      <Flex style={{width: "33.333%"}}>
                        <Flex type="column justify_center" style={{marginLeft: "25px"}}>
                          <Link to="/" >
                            <Text type="h3 white"><BsHouseDoor /></Text>
                          </Link>
                        </Flex>
                      </Flex>

                      <Flex style={{width: "33.333%"}} type="justify_center">
                        <Flex type="column justify_center">
                            <Link to="/">
                              <img src={logo} className={styles.logoDesk} />
                            </Link>
                        </Flex>
                      </Flex>

                      <Flex style={{width: "33.333%"}} type="justify_end">
                        <Flex type="column justify_center" style={{marginRight: "25px"}}>
                          <Link to="/cart" >
                            <Text type="h3 white"><BsBag /></Text>
                          </Link>
                        </Flex>
                      </Flex>
                    </Flex>
                  </div>
      return (<Responsive mobile={mobile} desk={desk} />);
  }
}

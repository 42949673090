import React from 'react';
import styles from './splash.module.scss';
import { Flex } from "@fidize/ui.flex"
import { Text } from "@fidize/ui.text"
import { Vspace } from "@fidize/ui.vspace"
import { Responsive } from "@fidize/ui.responsive"
import logo from './static/fidize.png';

export class Splash extends React.Component {
  render(){
    var desk = <div style={{position: "absolute", height: "100%", width: "100%"}}>
                <Flex type="justify_center" style={{height: "100%"}}>
                    <Flex type="column justify_center" style={{width: "20%", height: "100%"}}>
                      <img src={logo} className={this.props.exit ? styles.img + ' ' + styles.imgExit : styles.img} />
                      <Vspace desk="50px" mobile="35px" />
                      <span className={this.props.exit ? styles.textExit + " " + styles.text : styles.text}>
                        <Text type="h2 white" style={{textAlign: "center"}}>Attendere</Text>
                      </span>
                    </Flex>
                </Flex>
              </div>

    var mobile = <div style={{position: "absolute", height: "100%", width: "100%"}}>
                <Flex type="justify_center" style={{height: "100%"}}>
                    <Flex type="column justify_center" style={{width: "40%", height: "100%"}}>
                      <img src={logo} className={this.props.exit ? styles.img + ' ' + styles.imgExit : styles.img} />
                      <Vspace desk="50px" mobile="35px" />
                      <span className={this.props.exit ? styles.textExit + " " + styles.text : styles.text}>
                        <Text type="h3 white" style={{textAlign: "center"}}>Attendere</Text>
                      </span>
                    </Flex>
                </Flex>
              </div>

      return (<Responsive desk={desk} mobile={mobile} />);
  }
}

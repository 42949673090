import React from 'react'

import { Navigation } from '../navigation'
import '../../styles/main.scss';
import { Footer } from '../footer';
import { Splash } from '../splash';
import { FidizeUtils } from '@fidize/module.fidizeutils';
import { Vspace } from '@fidize/ui.vspace';
import { Text } from '@fidize/ui.text';
import * as NProgress from 'accessible-nprogress';
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
import { navigate } from "gatsby"

export class Layout extends React.Component {
  state = {
    loading: true,
    splashEnd: false
  }

  constructor(props){
    super(props);
  }

  componentDidMount(){
    NProgress.start();
    this.initAll();
  }

  initAll = async () => {
    window.cart = await FidizeUtils.initCart();

    window.toastSuccess = (text, id) => {
      toast.success(text, {
        id: id,
        duration: 4000,
        style: {
          padding: '16px',
          color: '#FFF',
          fontWeight: "build",
          borderRadius: "0px",
          backgroundColor: "#53b89c",
          width: "100%",
          maxWidth: "unset",
          padding: "5px 10px"
        },
        iconTheme: {
          primary: '#53b89c',
          secondary: '#53b89c'
        },
      })
      setTimeout(() => {
        toast.dismiss(id);
      }, 4000)
    }

    window.toastError = (text, id) => {
      toast.error(text, {
          id: id,
          style: {
            padding: '16px',
            color: '#FFF',
            fontWeight: "build",
            borderRadius: "0px",
            backgroundColor: "#de2a3a",
            width: "100%",
            maxWidth: "unset",
            padding: "5px 10px"
          },
          iconTheme: {
            primary: '#de2a3a',
            secondary: '#de2a3a'
          },
        })
      setTimeout(() => {
        toast.dismiss(id);
      }, 4000)
    }

    window.addToCart = (btn, product, variantId) => {
      btn.setDisabled(true);
      FidizeUtils.addToCart(window.cart, variantId, 1).then((newcart) => {
        window.cart = newcart;
        console.log(btn)

        var productId = product.id;
        //window.fbq("AddToCart", {content_ids:[productId], content_type: "product_group", value: 49.99, num_items: 1, content_name: "NFC Card Personalizzata", currency: "EUR", content_category: "Card"})
        btn.setDisabled(false);
        //window.toastSuccess("Prodotto aggiunto al tuo carrello");
        navigate("/cart")
      })
    }

    window.emptyCart = (btn) => {
      window.toastSuccess("Attendere");
      console.log(FidizeUtils)
      FidizeUtils.setProductCartQuantity(window.cart, 42126049575127, 0).then((newcart) => {
        window.cart = newcart;
        window.location.reload()
      })
    }

    NProgress.done();
    setTimeout(() => {
      this.setState({loading: false})
    }, 250)
    this.setState({splashEnd: true})
  }

  render() {
    return (
      <>
        {
          this.state.loading == true ? <Splash exit={this.state.splashEnd} /> :
          <>
            <Navigation />
              <Vspace desk="70px" mobile="50px" />
              <main>{this.props.children}</main>
              <Toaster position="bottom-center" containerStyle={{inset: "0px", fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", fontSize: "16px"}}/>
            <Footer />
          </>
        }
      </>
    )
  }
}

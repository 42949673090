const React = require("react")
const {Layout} = require("./src/components/layout/layout")

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
}

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <>{element}</>
}

exports.wrapRootElement = ({ element, props }) => {
    console.log(">>>", element)
    return (
        <>
          <Layout {...props}>{element}</Layout>
        </>
    )
}